import React, { useEffect, useRef } from 'react'
import configData from "../config.json"
import physiqueData from "../physique.json"
import "../styles/Canvas.css"

export default function Canvas() {
    let canvasRef = useRef(null)
    const queryParameters = new URLSearchParams(window.location.search)
    const GET = queryParameters.get("category") || "attack"

    useEffect(() => {
        // ######## Déclatation des variables de jeu ########
        let particles = [];
        let tailParticles = [];
        let toupies = [];
        let directionArrows = [];
        let center;
        let background;
        let gameOn = false;
        let placedPlayerToupie = false;
        let playerToupie;
        let oneDead = false;
        const mouse = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        };
        const canvasSize = {
            width: configData.canvas_width,
            height: configData.canvas_height
        }

        // ######## Initialisation du canvas #########
        const canvas = canvasRef.current;
        let rect = canvas.getBoundingClientRect();
        console.log(rect)
        const c = canvas.getContext('2d');

        // Classe Toupie
        class Toupie {
            constructor(id, x, y, radius, color, center, rotation, velocity, category, life, attack, defense) {
                this.id = id;
                this.x = x; // Position x
                this.y = y; // Position y
                this.radius = radius; // Rayon de la toupie
                this.color = color; // Couleur du fond de la toupie
                this.velocity = velocity; // Sa vitesse de déplacement (à calculer avec son x et y)       
                this.life = life; // Si les points de vie tombent à 0, la toupie éclate
                this.initialLife = life; // Utilisé pour la barre de vie
                this.center = center; // Centre de l'arène, là où elles seront attirées
                this.mass = radius; // Utilisé dans le calcul des collisions Newton
                this.angle = 0; // Rotation de la toupie actuelle
                this.rotation = rotation; // Vitesse de la rotation.
                this.speed_malus = 1 // Est utilisé pour arrêter la toupie lorsqu'elle tombe
                this.fulldead = false // True lorsque la toupie est à l'arrêt complet.
                this.bursted = false; // True lorsque la toupie n'a plus de point de vie
                this.alive = true; // False lorsqu'elle n'a plus de rotation. Alors il y a un speed malus et apràs quelques frames, elle passe en fulldead
                this.out = false // True lorsqu'elle est en dehors du stadium
                this.initialRotation = rotation;
                this.lifebar = new Lifebar(this);
                this.category = category; // Type de la toupie
                this.attack = attack; // Attaque lié au type de la toupie
                this.defense = defense; // Défense liée au type de la toupie 
            }
        
            // Affiche la toupie à l'écran
            draw() {
                drawToupie(this);
                this.lifebar.draw();
                // Affiche du texte au dessus de la souris (en debug)
                if (configData.debug === true) {
                    showDebugToupie([
                        "Rotation : " + Math.round(this.rotation*100)/100,
                        "Burst : " + Math.round(this.life*100)/100,
                        "Speed : " + Math.round((Math.round((Math.abs(this.velocity.x)*100/100) + (Math.abs(this.velocity.y)*100/100)))),
                        this.category,
                    ], this);
                }
            }
        
            // Est executé à chaque frame
            update() {
                // Check si la toupie est en dehors du terrain
                if (checkIfIsOutOfCircle(this, this.center)) {
                    this.out = true;
                    this.speed_malus -= 0.010
                    let rotation_slow = (1 - 1 / (1 + this.rotation * 1000));
                    this.velocity.x = (this.velocity.x * this.speed_malus) * rotation_slow * this.speed_malus;
                    this.velocity.y = (this.velocity.y * this.speed_malus) * rotation_slow * this.speed_malus;
                }

                // Tant qu'elle n'est pas sortie, on lui donne une trajectoire classique liée à son type
                if (!this.out) {
                    if (!this.fulldead) {
                        moove(this)
                    }
                }
        
                // Si la toupie est considéré en quasi arrêt de rotaiton, on lui donne un coup de pouce pour éviter des bugs
                if (!this.alive) {
                    ultraSlowToupie(this)
                }

                // Si la toupie est éjectée, on la fait rebondir sur les bords du canvas
                bounceOnEdge(this)
        
                // Si la toupie est en fin de rotation, on la considère quasi finie
                if (this.rotation < 2 && !oneDead) {
                    this.speed_malus -= 0.002;
                    this.alive = false;
                }

                // Explose la toupie si elle est la première à perdre toute sa vie
                if (this.life <= 0 && this.alive) {
                    this.burst()
                }

                // Tant que la toupie est vivante...
                if (this.alive) {
                    // Si la velocité additionée est assez grande, on fait une trainée
                    let sumVelocity = Math.abs(this.velocity.x) + Math.abs(this.velocity.y);
                    if (sumVelocity > configData.velocityBeforeTail) {
                        generateTailParticles(this);
                    }
                }
        
                this.x += this.velocity.x;
                this.y += this.velocity.y;

                // Changement de l'angle et de vélocité
                this.angle += 1;
                this.velocity.x *= physiqueData.friction_velocity * this.speed_malus;
                this.velocity.y *= physiqueData.friction_velocity * this.speed_malus;
        
                // Tant que les deux toupies sont en vie
                if (!oneDead) {
                    // Diminution progressive de la rotation et donc de sa vitesse de déplacement
                    this.rotation -= 0.02;
                }

                // Calcul de la vitesse de la toupie
                let speed = Math.round((Math.abs(this.velocity.x)*100/100) + (Math.abs(this.velocity.y)*100/100))

                // Si la toupie est trop rapide, on la limite à la vitesse max
                if (speed > configData.maxSpeed) {
                    this.velocity.x *= configData.maxSpeed / speed;
                    this.velocity.y *= configData.maxSpeed / speed;
                }
        
                // On affiche sa vie
                this.lifebar.update();
        
                this.draw();
            }
            
            burst() {
                // Création des particules d'explosion en fonction du radius de la toupie
                for (let i = 0; i < this.radius*6 ; i++) {
                    let radius2 = randomFromRange(1, 4);
                    let ranVelocityX2 = randomFromRange(-10, 10);
                    let ranVelocityY2 = randomFromRange(-10, 10);
                    let velocity2 = {
                        x: ranVelocityX2,
                        y: ranVelocityY2
                    };
                    particles.push(new Particle(this.x, this.y, radius2, this.color, velocity2, this.center))

                    let radius = randomFromRange(2, 6);
                    let ranVelocityX = randomFromRange(-5, 5);
                    let ranVelocityY =  randomFromRange(-5, 5);
                    let velocity = {
                        x: ranVelocityX,
                        y: ranVelocityY
                    };
                    particles.push(new Particle(this.x, this.y, radius, this.color, velocity, this.center))

                    let radius3 = randomFromRange(4, 8);
                    let ranVelocityX3 = randomFromRange(-3, 3);
                    let ranVelocityY3 =  randomFromRange(-3, 3);
                    let velocity3 = {
                        x: ranVelocityX3,
                        y: ranVelocityY3
                    };
                    particles.push(new Particle(this.x, this.y, radius3, this.color, velocity3, this.center))
                }
                oneDead = true
                this.bursted = true;
                this.rotation = 0;
                this.radius = 0;
            }
        }

        // Classe Particules
        class Particle {
            constructor(x, y, radius, color, velocity, center) {
                this.x = x;
                this.y = y;
                this.radius = radius;
                this.baseRadius = radius;
                this.center = center;
                this.color = color;
                this.velocity = velocity;
                this.killed = false;
                this.alpha = 0.8;
            }
        
            draw() {
                c.beginPath()
                c.arc(this.x, this.y, this.radius+1, 0, Math.PI * 2, false)
                c.fillStyle = '#FFFFFFFF';

                c.globalAlpha = this.alpha/7;
                c.fill()
                c.closePath()
                c.beginPath()
                c.arc(this.x, this.y, this.radius+2, 0, Math.PI * 2, false)
                c.fillStyle = this.color;
        
                c.globalAlpha = this.alpha/2;
                c.fill()
                c.closePath()
                c.beginPath()
                c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
                c.fillStyle = this.color;
        
                c.globalAlpha = this.alpha;
                c.fill()
                c.closePath()
            }
        
            update() {
                // Mouvement des particules
                this.x += this.velocity.x;
                this.velocity.x = this.velocity.x / physiqueData.gravitationalStrength * 0.99;
                this.velocity.y = this.velocity.y / physiqueData.gravitationalStrength * 0.99;
                this.y += this.velocity.y;
        
                // Disparait petit à petit
                this.radius -= 0.15
                if (this.radius < 1) {
                    delete this.x;
                    this.killed = true;
                }

                this.draw()
            }
        }
        
        // Classe Trainée des toupies
        class TailParticle {
            constructor(x, y,  color) {
                this.x = x;
                this.y = y;
                this.radius = 3;
                this.baseRadius = 5;
                this.color = color;
                this.killed = false;
                this.alpha = 0.5;
            }
        
            draw() {
                // On dessine les points à la position de la toupie
                c.beginPath()
                c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
                c.fillStyle = this.color;
                c.shadowColor = this.color;
                c.shadowBlur = 15;
                c.globalAlpha = 0.3;
                c.fill()
                c.closePath()
        
                c.beginPath()
                c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
                c.fillStyle = this.color;
                c.shadowColor = '#FFFFFFFF';
                c.shadowBlur = 10;
                c.globalAlpha = 0.2;
                c.fill()
                c.closePath()
        
                c.shadowColor = false;
                c.shadowBlur = 0;
            }
        
            update() {
                // Chaque frame, on réduit la taille de la particule
                this.radius -= 0.075
                // On tue la particule lorsqu'elle est trop petite
                if (this.radius < 0.75) {
                    delete this.x;
                    this.killed = true;
                }
                this.draw()
            }
        }
        
        // Classe de l'arène
        class Center {
            constructor(X, Y) {
                this.x = X;
                this.y = Y;
                this.radius = 300;
                this.mass = 1
                this.velocity = {
                    x: 0,
                    y: 0
                }
                this.life = 999999999;
                var gradient = c.createRadialGradient(X, Y, 0, X, Y, this.radius * configData.gradient_color_in_arena.startGap);
                gradient.addColorStop(0, configData.gradient_color_in_arena.center);
                gradient.addColorStop(1, configData.gradient_color_in_arena.out);
                this.color = gradient
            }
        
            draw() {
                // Affichage de l'arène
                c.beginPath();
                c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
                c.fillStyle = this.color;
                c.globalAlpha = 1;
                c.fill();
                c.closePath()
            }
        
            update() {
                this.draw();
            }
        }
        
        // Classe du Background
        class BackGround{
            constructor(center) {
                this.x = center.x;
                this.y = center.y;
                this.radius = 800;
                var gradient = c.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.radius ** configData.gradient_color_out_arena.startGap);
                gradient.addColorStop(0, configData.gradient_color_out_arena.center);
                gradient.addColorStop(1, configData.gradient_color_out_arena.out);
                this.color = gradient
            }

            draw() {
                // Affichage du background
                c.beginPath();
                c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
                c.fillStyle = this.color;
                c.globalAlpha = 1;
                c.fill();
                c.closePath()
            }
        
            update() {
                this.draw();
            }
        }
        
        // Classe de la flèche directionnelle
        class DirectionArrow{
            constructor(toupie, tox, toy) {
                this.toupie = toupie
                this.x = toupie.x;
                this.y = toupie.y;
                this.tox = tox;
                this.toy = toy;
            }

            draw(){
                // Affichage de la flèche
                c.beginPath();
                canvas_arrow(this.x, this.y, this.tox, this.toy);
                c.globalAlpha = 0.8;
                c.lineWidth = 3;
                c.strokeStyle = '#FFFFFFFF';
                c.stroke();
            }

            update(){
                this.x = this.toupie.x;
                this.y = this.toupie.y;
                this.tox = mouse.x;
                this.toy = mouse.y;
                // Détermine la vélocité de la toupie
        
                let diffX = this.tox-this.x;
                let diffY = this.toy-this.y;
        
                // Si la toupie a été lancé trop forte, on la limite à la vitesse max
                if (diffX > configData.maxStartSpeed) {
                    diffX  = configData.maxStartSpeed
                }
                if (diffX < -configData.maxStartSpeed) {
                    diffX  = -configData.maxStartSpeed
                }
                if (diffY > configData.maxStartSpeed) {
                    diffY  = configData.maxStartSpeed
                }
                if (diffY < -configData.maxStartSpeed) {
                    diffY  = -configData.maxStartSpeed
                }
        
                this.toupie.velocity.x = diffX/10;
                this.toupie.velocity.y = diffY/10;
                this.draw();
            }
        }
        
        // Classe de la barre de vie (burst)
        class Lifebar {
            constructor(toupie) {
                this.width = toupie.radius*2.5;
                this.color = toupie.color;
                this.prctage = 1; // C'est pas des pourcents
                this.toupie = toupie;
                this.x = this.toupie.x - this.width/2;
                this.y = this.toupie.y - this.toupie.radius - 20;
            }
        
            draw() {
                // Remplissage
                c.beginPath();
                c.rect(this.x, this.y, this.prctage * this.width  , 4);
                c.fillStyle = this.toupie.color;
                c.globalAlpha = 0.4;
                c.lineWidth = 0;
                c.fill();
                c.closePath();
            }
        
            update() {
                // On lui fait coller la toupie
                this.x = this.toupie.x - this.width/2;
                this.y = this.toupie.y - this.toupie.radius - 10;
                this.prctage = this.toupie.life / this.toupie.initialLife;
                this.draw();
            }
        
        
        }
        
        // ######## Event listeners ########
        // Traque la position de la souris
        canvas.addEventListener('mousemove', (event) => {
            console.log(mouse, event.clientX, event.clientY, event.clientX - rect.left, event.clientY - rect.top)
            mouse.x = event.clientX - rect.left
            mouse.y = event.clientY - rect.top
        });
        
        // Ajuste la taille du canvas si la fenêtre est redimensionné
        // canvas.addEventListener('resize', () => {
        //     canvas.width = canvasSize.width
        //     canvas.height = canvasSize.height
        //     init()
        // });
        
        // ######## Initialisation du canvas ########
        // Traque les clics de l'utilisateur
        canvas.addEventListener('click', (event) => {
            if (gameOn) {
                init();
            } else {
                if (placedPlayerToupie) {
                    gameOn = true;
                }
                placedPlayerToupie = true;
            }
            mouse.x = event.clientX - rect.left
            mouse.y = event.clientY - rect.top
        });
        
        
        // ######## Outils ########
        // Génere un int random entre min et max
        function randomIntFromRange(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
        
        // Génere un nombre décimal random entre min et max
        function randomFromRange(min, max) {
            return Math.random() * (max - min) + min;
        }
        
        // Sort une couleur random d'un tableau de couleur
        function randomColor(colors) {
            return colors[Math.floor(Math.random() * colors.length)]
        }
        
        // Donne la distance entre deux points
        function distance(x1, y1, x2, y2) {
            const xDist = x2 - x1;
            const yDist = y2 - y1;
            return Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2))
        }
        
        // Newton's equation to resolve a collision
        function resolveCollision(particle, otherParticle) {
            const xVelocityDiff = particle.velocity.x - otherParticle.velocity.x;
            const yVelocityDiff = particle.velocity.y - otherParticle.velocity.y;
            const xDist = otherParticle.x - particle.x;
            const yDist = otherParticle.y - particle.y;
        
            // Prevent accidental overlap of particles
            if (xVelocityDiff * xDist + yVelocityDiff * yDist >= 0) {
                // Grab angle between the two colliding particles
                const angle = -Math.atan2(otherParticle.y - particle.y, otherParticle.x - particle.x);
        
                // Store mass in var for better readability in collision equation
                const m1 = particle.mass;
                const m2 = otherParticle.mass;
        
                // Velocity before equation
                const u1 = rotate(particle.velocity, angle);
                const u2 = rotate(otherParticle.velocity, angle);
        
                // Velocity after 1d collision equation
                const v1 = {x: u1.x * (m1 - m2) / (m1 + m2) + u2.x * 2 * m2 / (m1 + m2), y: u1.y};
                const v2 = {x: u2.x * (m1 - m2) / (m1 + m2) + u1.x * 2 * m2 / (m1 + m2), y: u2.y};
        
                // Final velocity after rotating axis back to original location
                const vFinal1 = rotate(v1, -angle);
                const vFinal2 = rotate(v2, -angle);
        
                //Add rotation bonus when they contact
                if(particle.alice || otherParticle.alive){
                    vFinal1.x *= 2;
                    vFinal1.y *= 2;
                }
        
                // Swap particle velocities for realistic bounce effect
                particle.velocity.x = vFinal1.x * physiqueData.friction_object;
                particle.velocity.y = vFinal1.y * physiqueData.friction_object;
                
                otherParticle.velocity.x = vFinal2.x * physiqueData.friction_object;
                otherParticle.velocity.y = vFinal2.y * physiqueData.friction_object;
            }
        }
        
        // Empêche une toupie éjectée de revenir sur l'arène
        function checkCollisionToupieCenter(toupie, center){
            if (distance(toupie.x, toupie.y, center.x, center.y) < toupie.radius + center.radius) {
                resolveCollision(toupie, center)
                // Reset velocité du center parce qu'il a modifié lors du resolve colision alors qu'il faudrait pas
                center.velocity.x = 0;
                center.velocity.y = 0;
            }
        }
        
        // Utilisé dans resolveCollision
        function rotate(velocity, angle) {
            const rotatedVelocities = {
                x: velocity.x * Math.cos(angle) - velocity.y * Math.sin(angle),
                y: velocity.x * Math.sin(angle) + velocity.y * Math.cos(angle)
            };
            return rotatedVelocities;
        }
        
        // Regarde dans toutes les toupies s'il y a des collisions
        function CheckAllToupiesCollisions(toupies) {
            toupies.forEach(toupie1 => {
                CheckWallCollisions(toupie1)
                // Si la toupie est out, on check qu'elle ne puisse pas revenir au centre
                if (toupie1.out) {
                    checkCollisionToupieCenter(toupie1, toupie1.center)
                }
                toupies.forEach(toupie2 => {
                    if (toupie1.id !== toupie2.id && !toupie1.bursted && !toupie2.bursted) {
                        // Si deux toupies rentrent en contact (attention, lancé une fois par toupie)
                        CheckTwoToupiesCollision(toupie1, toupie2);
                    }
                })
            })
        }

        
        // Rebondit si la toupie touche le bord de l'arène (à voir pour certaines zones de l'arène seulement)
        function CheckWallCollisions(toupie) {
            if (distance(toupie.x, toupie.y, toupie.center.x, toupie.center.y) > toupie.center.radius - toupie.radius) {
                let velocitySpeed = Math.round(Math.abs(toupie.velocity.x) + Math.abs(toupie.velocity.y))

                // Le mur fait des dommages tant que le duel est en cours
                if (!oneDead) {
                    toupie.rotation -= 1
                }

                // On ajoute des particules en fonction de la vitesse pour indiquer que la toupie prend des dommages
                for (let i = 0; i < velocitySpeed * 5; i++) {
                    let radius = randomFromRange(2, 8);
                    let ranVelocityX = toupie.velocity.x + randomFromRange(-5, 0);
                    let ranVelocityY = toupie.velocity.y + randomFromRange(-5, 0);
                    let velocity = {
                        x: ranVelocityX,
                        y: ranVelocityY
                    };
                    particles.push(new Particle(toupie.x, toupie.y, radius, toupie.color, velocity, toupie.center))
                    
                    let radius2 = randomFromRange(1, 4);
                    let ranVelocityX2 = toupie.velocity.x + randomFromRange(-5, 5);
                    let ranVelocityY2 = toupie.velocity.y + randomFromRange(-5, 2);
                    let velocity2 = {
                        x: ranVelocityX2,
                        y: ranVelocityY2
                    };
                    particles.push(new Particle(toupie.x, toupie.y, radius2, toupie.color, velocity2, toupie.center))
                }
                // Calculer l'angle de la toupie par rapport au centre de l'arène
                const angleFromCenter = Math.atan2(toupie.y - center.y, toupie.x - center.x);
                // Calculer la position de la toupie sur le bord de l'arène
                const newX = center.x + (toupie.center.radius - toupie.radius) * Math.cos(angleFromCenter);
                const newY = center.y + (toupie.center.radius - toupie.radius) * Math.sin(angleFromCenter);
                // Mettre à jour la position de la toupie sur le bord de l'arène
                toupie.x = newX;
                toupie.y = newY;
                // Calculer l'angle de rebondissement
                const angleOfReflection = angleFromCenter + Math.PI; // Inverser la direction
                // Calculer la vitesse de la toupie après rebondissement
                const speed = Math.sqrt(toupie.velocity.x * toupie.velocity.x + toupie.velocity.y * toupie.velocity.y);
                // Mettre à jour la vitesse de la toupie pour simuler la réflexion
                toupie.velocity.x = speed * Math.cos(angleOfReflection);
                toupie.velocity.y = speed * Math.sin(angleOfReflection);
            }
        }
        
        // Regarde s'il y a collision entre deux toupies
        function CheckTwoToupiesCollision(toupie1, toupie2) {
            if (distance(toupie1.x, toupie1.y, toupie2.x, toupie2.y) < toupie2.radius + toupie1.radius) {
                resolveCollision(toupie1, toupie2);
                if (!oneDead) {
                    // On reduit la rotation des deux toupies
                    toupie1.rotation *= physiqueData.friction_object;
                    toupie2.rotation *= physiqueData.friction_object;

                    let sumVelocity1 = Math.abs(toupie1.velocity.x) + Math.abs(toupie1.velocity.y)
                    let sumVelocity2 = Math.abs(toupie2.velocity.x) + Math.abs(toupie2.velocity.y)
                    
                    // Équation dégats subis par toupie
                    let damagesToupie = Math.round((toupie2.attack - (toupie2.attack * (toupie1.defense / 100))) * (1 + (((Math.round(toupie2.rotation) + (Math.round((Math.abs(toupie2.velocity.x)*100/100) + (Math.abs(toupie2.velocity.y)*100/100)))) / 5) / 100 )));
                    let damagesRotation = damagesToupie / 5

                    if (sumVelocity1 > 25) {
                        damagesToupie *= 2
                        damagesRotation *= 1.3
                    }
                    
                    let bonus = Math.floor(Math.random() * 6);

                    if (sumVelocity1 > sumVelocity2) {
                        toupie1.life -= damagesRotation;
                        toupie1.rotation -= damagesRotation
                    } else {
                        toupie1.life -= damagesRotation + bonus;
                        toupie1.rotation -= damagesRotation + bonus;
                    }
                    
                    // Création des particules en fonction des dégats
                    for (let i = 0; i < damagesToupie * 5; i++) {
                        let radius = randomFromRange(2, 8);
                        let ranVelocityX = toupie1.velocity.x + randomFromRange(-5, 5);
                        let ranVelocityY = toupie1.velocity.y + randomFromRange(-5, 2);
                        let velocity = {
                            x: ranVelocityX,
                            y: ranVelocityY
                        };
                        particles.push(new Particle(toupie1.x, toupie1.y, radius, toupie1.color, velocity, toupie1.center))
                        
                        let radius2 = randomFromRange(1, 4);
                        let ranVelocityX2 = toupie1.velocity.x + randomFromRange(-5, 5);
                        let ranVelocityY2 = toupie1.velocity.y + randomFromRange(-5, 2);
                        let velocity2 = {
                            x: ranVelocityX2,
                            y: ranVelocityY2
                        };
                        particles.push(new Particle(toupie1.x, toupie1.y, radius2, toupie1.color, velocity2, toupie2.center))
                    }
                }
            }
        }
        
        // Dessine une toupie
        function drawToupie(toupie) {
            // Sauvegarde de l'état du canvas avant de le faire tourner
            c.save();
            // Place le point d'origine du canvas au centre de la toupie
            c.translate(toupie.x, toupie.y);
            // On tourne avec l'angle
            c.rotate(toupie.angle * toupie.rotation * Math.PI / 180);
            // Tracage du cercle
            if (toupie.alive){
                c.strokeStyle = '#000000';
            } else {
                c.strokeStyle = '#000000';
            }
        
            c.lineWidth = 1;
            c.beginPath();
            c.arc(0, 0, toupie.radius, 0, Math.PI * 2, false);
            c.fillStyle = toupie.color;
            c.shadowColor = toupie.color;
            c.shadowBlur = 7;
            c.globalAlpha = 1;
            c.fill();
            c.stroke();
            c.closePath()
        
            // Tracage des pics
            c.beginPath();
            c.lineWidth = 3;
            // eslint-disable-next-line no-unused-expressions
            c.line
            c.moveTo(-toupie.radius * 13 / 16, -toupie.radius * 13 / 16);
            c.lineTo(+toupie.radius * 13 / 16, +toupie.radius * 13 / 16);
            c.moveTo(+toupie.radius * 13 / 16, -toupie.radius * 13 / 16);
            c.lineTo(-toupie.radius * 13 / 16, +toupie.radius * 13 / 16);
            if (toupie.rotation <= 0) {
                c.strokeStyle = '#000000';
            } else {
                c.strokeStyle = '#ffffffff';
            }
            c.stroke();
        
            // On replace le point d'origine du canvas à un truc normal
            c.restore();
        }
        
        // Affiche le debug au dessus d'une toupie
        function showDebugToupie(strings, toupie){
            c.globalAlpha = 1;
            c.font = '25px Arial';
            c.fillStyle = "rgba(255, 255, 255, 0.8)";
            c.textAlign = "center";
            let textSpace = 0;
            strings.forEach(string => {
                c.fillText(string, toupie.x, toupie.y-toupie.radius - 15 - textSpace);
                textSpace += 30
            })
        }
        
        // Check si un point est dans le cercle
        function checkIfIsOutOfCircle(point, circle) {
            if (Math.pow((point.x - circle.x), 2) + Math.pow((point.y - circle.y), 2) > Math.pow(circle.radius, 2) && !point.out){
                return true
            }
        }
        
        // Lorsque qu'une toupie a une rotation trop basse, sa velocité diminue tres vite
        function ultraSlowToupie(toupie) {
            if (oneDead && toupie.alive) {
                // Juste pour empêcher la toupie de ralentir si elle a gagné
            } else if (toupie.rotation < 0.01) {
                // Lorsque la toupie est trop lente, on l'immobilise
                toupie.fulldead = true;
                oneDead = true
                toupie.rotation = 0
                toupie.velocity.x *= 0.85;
                toupie.velocity.y *= 0.85;
            } else {
                // Ralentit rapidement la toupie
                let rotation_slow = (1 - 1 / (1 + toupie.rotation * 1000));
                toupie.velocity.x = toupie.velocity.x  * toupie.speed_malus;
                toupie.velocity.y = toupie.velocity.y  * toupie.speed_malus;
                toupie.velocity.x *= rotation_slow;
                toupie.velocity.y *= rotation_slow;
            }
        }
        
        // Fait rebondir sur les bords de l'écran
        function bounceOnEdge(moovable) {
            if (moovable.x - moovable.radius < 0 || moovable.x + moovable.radius > configData.canvas_width) {
                if (moovable.x - moovable.radius < 0) {
                    moovable.x =  moovable.radius+1;
                }
                if (moovable.x + moovable.radius  > configData.canvas_width) {
                    moovable.x = configData.canvas_width -  moovable.radius -1;
                }
                moovable.velocity.x *=-1 // Pour éviter qu'elle se coince au bord à l'infini
                moovable.rotation *= physiqueData.friction_edge_screen;
        
                let damagesX = moovable.velocity.x
                let damages = Math.pow(Math.abs(damagesX), 3) / 5000;
                moovable.life -= damages;
            }
        
            if (moovable.y - moovable.radius < 0 || moovable.y + moovable.radius > configData.canvas_height) {
                if(moovable.y - moovable.radius < 0){
                    moovable.y =  moovable.radius+1;
                }
                if(moovable.y + moovable.radius  > configData.canvas_height){
                    moovable.y = configData.canvas_height -  moovable.radius -1;
                }
                moovable.velocity.y *=-1
                moovable.rotation *= physiqueData.friction_edge_screen;
        
                let damagesY = moovable.velocity.y
                let damages = Math.pow(Math.abs(damagesY), 3) / 5000;
                moovable.life -= damages;
            }
        }
        
        // Détermine le mouvement de la toupie en fonction de son type
        function moove(toupie){
            if(toupie.category === "attack"){
                attackMovement(toupie, toupie.center)
            }
            if(toupie.category === "defense" || toupie.category === "stamina"){
                passiveMoovement(toupie, toupie.center)
            }
        }
        
        // Détermine le mouvement d'une toupie de type attaque
        function attackMovement(toupie, center) {
            if (!oneDead) {
                let xDiff = center.x - toupie.x;
                let yDiff = center.y - toupie.y;
            
                // Prendre que les valeurs : passe en positif si négatif, ou bien reste en positif
                let additionDistance = Math.sign(xDiff) * xDiff + Math.sign(yDiff) * yDiff;
            
                let xWanted = xDiff / additionDistance;
                let yWanted = yDiff / additionDistance;
            
                let xDeplacement = xWanted;
                let yDeplacement = yWanted;
            
                let rotation_slow = (1 - 1 / (1 + toupie.rotation * 40));
            
                toupie.velocity.x += xDeplacement / physiqueData.gravitationalStrength * toupie.speed_malus;
                toupie.velocity.y += yDeplacement / physiqueData.gravitationalStrength * toupie.speed_malus;
                toupie.velocity.x *= rotation_slow;
                toupie.velocity.y *= rotation_slow;
            } else {
                let xDiff = center.x - toupie.x;
                let yDiff = center.y - toupie.y;
            
                let xWanted = xDiff / 100;
                let yWanted = yDiff / 100;
            
                let xDeplacement = xWanted;
                let yDeplacement = yWanted;
            
                let rotation_slow = (1 - 1 / (1 + toupie.rotation * 10000));
            
                toupie.velocity.x = toupie.velocity.x * rotation_slow * physiqueData.defense_instability + xDeplacement * physiqueData.gravitationalStrength * rotation_slow * toupie.speed_malus ;
                toupie.velocity.y = toupie.velocity.y * rotation_slow * physiqueData.defense_instability + yDeplacement * physiqueData.gravitationalStrength * rotation_slow * toupie.speed_malus ;
            }
            
        }
        
        // Détermine le mouvement d'une toupie de type défense et endurance
        // La toupie sera repoussé par les bords
        function passiveMoovement(toupie, center) {
            let xDiff = center.x - toupie.x;
            let yDiff = center.y - toupie.y;
        
            let xWanted = xDiff / 100;
            let yWanted = yDiff / 100;
        
            let xDeplacement = xWanted;
            let yDeplacement = yWanted;
        
            let rotation_slow = (1 - 1 / (1 + toupie.rotation * 10000));
        
            toupie.velocity.x = toupie.velocity.x * rotation_slow * physiqueData.defense_instability + xDeplacement * physiqueData.gravitationalStrength * rotation_slow * toupie.speed_malus ;
            toupie.velocity.y = toupie.velocity.y * rotation_slow * physiqueData.defense_instability + yDeplacement * physiqueData.gravitationalStrength * rotation_slow * toupie.speed_malus ;
        }
        
        // Sort une valeur random d'un array
        function randomFromArray(categories) {
            return categories[Math.floor(Math.random() * categories.length)]
        }
        
        // Génère les particules de trainées d'une toupie
        function generateTailParticles(toupie){
            tailParticles.push(new TailParticle(toupie.x, toupie.y, toupie.color))
        }
        
        function canvas_arrow(fromx, fromy, tox, toy) {
            var headlen = 10; // Longueur de la pointe en pixels
            var dx = tox - fromx;
            var dy = toy - fromy;
            var angle = Math.atan2(dy, dx);
            c.moveTo(fromx, fromy);
            c.lineTo(tox, toy);
            c.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
            c.moveTo(tox, toy);
            c.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
        }
        
        // Création d'une toupie random (si aucune valeur donnée)
        function newRandomToupie(toupieX = randomIntFromRange(canvasSize.width / 3, canvasSize.width * 5 / 8), toupieY = randomIntFromRange(canvasSize.height / 3, canvasSize.height * 5 / 8), category = null, id = toupies.length){
            let VelocityX = randomIntFromRange(-10,10);
            let VelocityY = randomIntFromRange(-10,10);
            let velocity = {
                x: VelocityX,
                y: VelocityY
            };
            let color;
            let rotation;
            let life;
            let attack;
            let defense;
            let center = new Center(configData.canvas_width / 2, configData.canvas_height / 2);
        
            if(!category){
                category = randomFromArray(configData.categories);
            }
            if (category === 'attack'){
                color = randomColor(configData.color_attack)
                attack = configData.attackStats.attack
                defense = configData.attackStats.defense
                rotation = configData.attackStats.stamina;
                life = configData.attackStats.burst_resistance;
            }
            if (category === 'defense'){
                color = randomColor(configData.color_defense)
                attack = configData.defenseStats.attack
                defense = configData.defenseStats.defense
                rotation = configData.defenseStats.stamina;
                life = configData.defenseStats.burst_resistance;
            }
            if (category === 'stamina'){
                color = randomColor(configData.color_stamina)
                attack = configData.staminaStats.attack
                defense = configData.staminaStats.defense
                rotation = configData.staminaStats.stamina;
                life = configData.staminaStats.burst_resistance;
            }
            return new Toupie(id, toupieX, toupieY, 20, color, center, rotation, velocity, category, life, attack, defense);
        }
        
        // ######## Fonctions moteur canvas ########
        // Lance la partie
        function init() {
            gameOn = false;
            placedPlayerToupie = false;
            oneDead = false;
            toupies = [];
            particles = [];
            tailParticles = [];
            directionArrows = [];
            center = new Center(canvasSize.width / 2, canvasSize.height / 2);
            
            // Création de la toupie du joueur
            playerToupie = newRandomToupie(mouse.x, mouse.y, GET);
            toupies.push(playerToupie);
            // Création de la fleche de direction du joueur
            let directionArrow = new DirectionArrow(playerToupie, mouse.x, mouse.y);
            directionArrows.push(directionArrow);
            
            for (let i = 0; i < 1; i++) {
                toupies.push(newRandomToupie());
            }
            background = new BackGround(center);
        }
        
        // Est executé à chaque frame
        function animate() {
            // Optimisation des particules
            tailParticles = tailParticles.slice(-40)
            particles = particles.slice(-800)
            // Clear page
            c.clearRect(0, 0, canvas.width, canvas.height);
            //update du center de l'arene et du background
            background.update();
            center.update();
        
            if (placedPlayerToupie) {
                if (gameOn) {
                    CheckAllToupiesCollisions(toupies);
                    // Boucle
                    // Update de la trainée des toupies
                    tailParticles.forEach(tailParticle=>{
                        tailParticle.update()
                    })
                    // Update particles
                    particles.forEach(particle => {
                        particle.update()
                    });
                    // Update toupies
                    toupies.forEach(toupie => {
                        toupie.update()
                    });
                } else {
                    // La toupie est positionnée, traçage de la flèche pour la direction
                    toupies.forEach(toupie => {
                        toupie.draw()
                        toupie.lifebar.update();
                    });
                    directionArrows.forEach((directionArrow=>{
                        directionArrow.update()
                    }))
                }
            } else {
                // Positionnemnt de la toupie
                toupies.forEach(toupie => {
                    toupie.draw()
                    toupie.lifebar.update();
                });
                playerToupie.x = mouse.x;
                playerToupie.y = mouse.y;
            }
            requestAnimationFrame(animate);
            // End animate
        }
        
        // ######## Lancement du canvas ########
        init()
        animate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <canvas 
            ref={canvasRef}
            id='Canvas'
            className='Canvas'
            width={700}
            height={700}
        />
    )
}