import '../styles/App.css';
import Canvas from './Canvas';

function App() {
  return (
    <>
      <Canvas />
    </>
  );
}

export default App;
